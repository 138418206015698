import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Circle, circleVariants } from '../../../Comments/components'
import { ConditionalWrapper } from '../../../ConditionalWrapper'
import { Icon, ReviewerIcon } from '../../../Icon'
import { Image } from '../../../Image'
import { Link } from '../../../Link'
import styles from './Avatar.style'

const ColumnistAvatarStyled = styled.div(props => ({ ...styles(props) }))

function ColumnistAvatar({ className, variant, href, title, avatarImageId, ...rest }) {
    if (!title) {
        return null
    }

    return (
        <ColumnistAvatarStyled className={className} variant={variant} {...rest}>
            <div className="avatarInner">
                <ConditionalWrapper
                    condition={!!href}
                    Wrapper={Link}
                    href={href}
                    title={`Kolumnist ${title}`}
                    className="avatarLink">
                    <div className="avatarImage_wrapper">
                        {variant === 'alpha' && <div className="avatarCircle_3 backgroundCircle" />}
                        {avatarImageId ? (
                            <Image
                                className="avatarImage"
                                classNameProgressive="avatarImage_progressive"
                                image={avatarImageId}
                                title={title}
                                alt={`${title} avatar`}
                            />
                        ) : (
                            <Icon icon={ReviewerIcon} size={80} className="avatarImage_placeholder" />
                        )}
                        <Circle size={125} className="avatarCircle_0" variant={circleVariants.ALPHA} />
                        <Circle size={135} className="avatarCircle_1" variant={circleVariants.ALPHA} />
                        <Circle size={145} className="avatarCircle_2" variant={circleVariants.ALPHA} />
                    </div>
                </ConditionalWrapper>
            </div>
        </ColumnistAvatarStyled>
    )
}

const columnistAvatarVariants = Object.freeze({
    ALPHA: 'alpha', // listing avatar
    BETA: 'beta', // single columnist avatar
    GAMMA: 'gamma' // single column avatar
})

ColumnistAvatar.propTypes = {
    className: PropTypes.string,
    avatarImageId: PropTypes.string,
    title: PropTypes.string,
    href: PropTypes.string,
    variant: PropTypes.oneOf([...Object.values(columnistAvatarVariants)])
}

ColumnistAvatar.defaultProps = {
    className: undefined,
    avatarImageId: undefined,
    href: undefined,
    title: undefined,
    variant: columnistAvatarVariants.ALPHA
}

export { columnistAvatarVariants }
export default ColumnistAvatar
