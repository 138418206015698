import { toRem, toRems } from '../../../../helpers/theme'

const style = ({ theme, variant }) => {
    const config = {
        alpha: {
            title: {
                fontSize: {
                    sm: toRem(20),
                    md: toRem(28)
                },
                lineHeight: {
                    sm: toRem(32),
                    md: toRem(40)
                },
                fontFamily: theme.typography.fontFamilyAlt,
                fontWeight: theme.typography.font.weight.regular,
                fontStyle: theme.typography.font.style.italic
            },
            subtitle: {
                fontSize: {
                    sm: toRem(14),
                    md: toRem(14)
                },
                lineHeight: {
                    sm: toRem(28),
                    md: toRem(28)
                },
                fontFamily: theme.typography.fontFamily,
                fontWeight: theme.typography.font.weight.regular,
                fontStyle: theme.typography.font.style.normal
            },

            description: {
                fontSize: {
                    sm: toRem(14),
                    md: toRem(16)
                },
                lineHeight: {
                    sm: toRem(26),
                    md: toRem(28)
                },
                fontFamily: theme.typography.fontFamily,
                fontWeight: theme.typography.font.weight.regular,
                fontStyle: theme.typography.font.style.normal
            },
            avatar: {
                margin: {
                    md: toRems([-60, 0, 30, 0]),
                    sm: toRems([-50, 0, 20, 0])
                }
            }
        },
        beta: {
            title: {
                fontSize: {
                    sm: toRem(28),
                    md: toRem(52)
                },
                lineHeight: {
                    sm: toRem(32),
                    md: toRem(40)
                },
                fontFamily: theme.typography.fontFamilyAlt,
                fontWeight: theme.typography.font.weight.regular,
                fontStyle: theme.typography.font.style.italic
            },
            subtitle: {
                fontSize: {
                    sm: toRem(14),
                    md: toRem(16)
                },
                lineHeight: {
                    sm: toRem(28),
                    md: toRem(28)
                },
                fontFamily: theme.typography.fontFamily,
                fontWeight: theme.typography.font.weight.regular,
                fontStyle: theme.typography.font.style.normal
            },

            description: {
                fontSize: {
                    sm: toRem(14),
                    md: toRem(16)
                },
                lineHeight: {
                    sm: toRem(26),
                    md: toRem(28)
                },
                fontFamily: theme.typography.fontFamily,
                fontWeight: theme.typography.font.weight.regular,
                fontStyle: theme.typography.font.style.normal
            },
            avatar: {
                margin: {
                    md: toRems([0, 30, 0, 0]),
                    sm: toRems([0, 30, 0, 0])
                }
            }
        },
        gamma: {
            title: {
                fontSize: {
                    sm: toRem(16),
                    md: toRem(16)
                },
                lineHeight: {
                    sm: toRem(22),
                    md: toRem(26)
                },
                fontFamily: theme.typography.fontFamily,
                fontWeight: theme.typography.font.weight.semiBold,
                fontStyle: theme.typography.font.style.normal
            },
            avatar: {
                margin: {
                    md: toRems([0, 30, 0, 0]),
                    sm: toRems([0, 15, 0, 0])
                }
            }
        }
    }
    const generateFontProperties = className => ({
        fontFamily: config?.[variant]?.[className]?.fontFamily,
        fontWeight: config?.[variant]?.[className]?.fontWeight,
        fontStyle: config?.[variant]?.[className]?.fontStyle,
        fontSize: config?.[variant]?.[className]?.fontSize?.sm,
        lineHeight: config?.[variant]?.[className]?.lineHeight?.sm,
        [theme.breakpoints.up('md')]: {
            fontSize: config?.[variant]?.[className]?.fontSize?.md,
            lineHeight: config?.[variant]?.[className]?.lineHeight?.md
        }
    })
    return {
        '& .columnistCard': {
            display: 'flex',
            flexDirection: variant?.match(/beta|gamma/gi) ? 'row' : 'column',
            justifyContent: variant?.match(/beta|gamma/gi) ? 'flex-start' : 'center',
            alignItems: 'center',
            textAlign: variant === 'alpha' ? 'center' : 'left',
            ...(variant === 'alpha' && {
                margin: toRems([60, 0, 10]),
                padding: toRems([0, 25, 25]),
                border: `solid 1px ${theme.colors.columnist?.card?.border || theme.colors.devWarning}`
            }),
            [theme.breakpoints.up('md')]: {
                justifyContent: variant === 'beta' ? 'flex-start' : 'center',
                ...(variant === 'alpha' && {
                    margin: toRems([70, 0, 10]),
                    padding: toRems([0, 40, 40])
                })
            },
            '&_avatar': {
                margin: config?.[variant]?.avatar?.margin?.sm,
                [theme.breakpoints.up('md')]: {
                    margin: config?.[variant]?.avatar?.margin?.md
                }
            },
            '&_inner, &_link': {
                display: 'flex',
                alignItems: variant?.match(/beta|gamma/gi) ? 'flex-start' : 'center',
                flexDirection: 'column',
                justifyContent: 'center'
            },
            ...(variant === 'gamma' && {
                margin: toRems([20, 0, 35]),
                '&_lead': {
                    color: theme.colors.columnist?.card?.label,
                    fontSize: toRem(14),
                    lineHeight: toRem(20),
                    [theme.breakpoints.up('md')]: {
                        fontSize: toRem(16),
                        lineHeight: toRem(22)
                    }
                }
            }),
            '&_title': {
                color: theme.colors.columnist?.card?.title || theme.colors.devWarning,
                ...generateFontProperties('title')
            },
            ...(variant?.match(/alpha|beta/gi) && {
                '&_subtitle': {
                    color: theme.colors.columnist?.card?.subtitle || theme.colors.devWarning,
                    ...generateFontProperties('subtitle')
                },
                '&_description': {
                    color: theme.colors.columnist?.card?.description || theme.colors.devWarning,
                    ...generateFontProperties('description')
                }
            }),
            '&_share': {
                display: 'flex',
                alignItems: 'center',
                margin: toRems([20, 0, 0, 0]),
                ...(variant === 'alpha' && {
                    justifyContent: 'center'
                }),
                '&Item': {
                    display: 'flex',
                    alignItems: 'center',
                    ...(variant === 'alpha' && {
                        '&:first-of-type': {
                            position: 'relative',
                            '&::before': {
                                position: 'absolute',
                                content: '""',
                                left: toRem(-40),
                                flexShrink: 0,
                                margin: toRems([0, 10, 0, 0]),
                                width: toRem(30),
                                height: 1,
                                background: theme.colors.text.default
                            }
                        },
                        '&:last-of-type': {
                            position: 'relative',
                            '&::after': {
                                position: 'absolute',
                                content: '""',
                                right: toRem(-50),
                                flexShrink: 0,
                                margin: toRems([0, 10, 0, 0]),
                                width: toRem(30),
                                height: 1,
                                background: theme.colors.text.default
                            }
                        }
                    })
                },
                '&Name': {
                    marginLeft: toRem(10),
                    fontSize: toRem(14),
                    [theme.breakpoints.down('sm')]: {
                        display: 'none'
                    }
                },

                '& > div': {
                    '&:not(:last-of-type)': {
                        marginRight: toRem(20),
                        ...(variant === 'alpha' && {
                            marginRight: toRem(6)
                        }),
                        [theme.breakpoints.down('sm')]: {
                            marginRight: toRem(10)
                        }
                    }
                }
            }
        }
    }
}

export default style
