import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { ConditionalWrapper } from '../../../ConditionalWrapper'
import { Link } from '../../../Link'
import { SocialItem, socialItemColorVariants } from '../../../Social/components'
import { ColumnistAvatar } from '../Avatar'
import styles from './Card.style'

const CardStyled = styled.div(props => ({ ...styles(props) }))

function Card({ className, columnist, variant, ...rest }) {
    const authorProfession = columnist?.extended_attributes?.author_profession || ''
    const authorLead = columnist?.extended_attributes?.author_lead || ''

    const authorFacebook = columnist?.extended_attributes?.author_social_facebook || ''
    const authorFacebookName =
        authorFacebook.length > 0 && authorFacebook.includes('.com/')
            ? authorFacebook?.split('.com/')[1].replace('/', '')
            : ''

    const authorInstagram = columnist?.extended_attributes?.author_social_instagram || ''
    const authorInstagramName =
        authorInstagram.length > 0 && authorInstagram.includes('.com/')
            ? authorInstagram?.split('.com/')[1].replace('/', '')
            : ''

    const authorWebsite = columnist?.extended_attributes?.author_social_web_site || ''
    const authorWebsiteName =
        authorWebsite.length > 0 && authorWebsite.includes('www.')
            ? authorWebsite?.split('www.')[1].replace('/', '')
            : ''

    if (!columnist) {
        return null
    }

    return (
        <CardStyled className={className} variant={variant} {...rest}>
            <div className="columnistCard">
                <div>
                    <ColumnistAvatar
                        href={`/kolumnisti/${columnist?.slug}`}
                        title={columnist?.title}
                        avatarImageId={columnist?.image?.id}
                        className="columnistCard_avatar"
                        variant={variant}
                        columnistId={columnist?.id}
                    />
                </div>
                <div>
                    <ConditionalWrapper
                        className="columnistCard_inner"
                        condition={variant !== 'beta'}
                        Wrapper={Link}
                        href={`/kolumnisti/${columnist?.slug}`}
                        title={`Kolumnist ${columnist?.title}`}>
                        {variant === 'gamma' && <span className="columnistCard_lead">Kolumnu piše</span>}
                        {columnist?.title && <span className="columnistCard_title">{columnist?.title}</span>}
                        {variant?.match(/alpha|beta/gi) && authorProfession && (
                            <div className="columnistCard_subtitle">{authorProfession}</div>
                        )}
                        {variant === 'alpha' && authorLead && (
                            <span className="columnistCard_description">{authorLead}</span>
                        )}
                    </ConditionalWrapper>
                    {variant !== 'gamma' && (
                        <div className="columnistCard_share">
                            {authorWebsite.length > 0 && (
                                <div className="columnistCard_shareItem">
                                    <SocialItem
                                        icon="url"
                                        hrefIsExternal
                                        href={authorWebsite}
                                        color={socialItemColorVariants.META}
                                    />
                                    {variant !== 'alpha' && (
                                        <span className="columnistCard_shareName">{authorWebsiteName}</span>
                                    )}
                                </div>
                            )}
                            {authorFacebook.length > 0 && (
                                <div className="columnistCard_shareItem">
                                    <SocialItem
                                        icon="facebook"
                                        href={authorFacebook}
                                        hrefIsExternal
                                        color={socialItemColorVariants.META}
                                    />
                                    {variant !== 'alpha' && (
                                        <span className="columnistCard_shareName">{authorFacebookName}</span>
                                    )}
                                </div>
                            )}
                            {authorInstagram.length > 0 && (
                                <div className="columnistCard_shareItem">
                                    <SocialItem
                                        hrefIsExternal
                                        icon="instagram"
                                        href={authorInstagram}
                                        color={socialItemColorVariants.META}
                                    />
                                    {variant !== 'alpha' && (
                                        <span className="columnistCard_shareName">{authorInstagramName}</span>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </CardStyled>
    )
}
const columnistCardVariants = Object.freeze({
    ALPHA: 'alpha', // listing card
    BETA: 'beta', // single columnist card
    GAMMA: 'gamma' // single column card
})
Card.propTypes = {
    className: PropTypes.string,
    columnist: PropTypes.shape({
        extended_attributes: PropTypes.shape({
            author_profession: PropTypes.string,
            author_lead: PropTypes.string,
            author_social_facebook: PropTypes.string,
            author_social_instagram: PropTypes.string,
            author_social_web_site: PropTypes.string
        }),
        slug: PropTypes.string,
        title: PropTypes.string,
        id: PropTypes.string,
        image: PropTypes.shape({
            id: PropTypes.string
        })
    }),
    variant: PropTypes.oneOf([...Object.values(columnistCardVariants)])
}

Card.defaultProps = {
    className: undefined,
    columnist: undefined,
    variant: columnistCardVariants.ALPHA
}

export { columnistCardVariants }
export default withErrorBoundary(Card, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[ColumnistCard]: ', error, componentStack)
    }
})
