/* eslint-disable camelcase, max-len */
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { Media } from '@hmn/rtl-web-core/components/Media'
import { useInnerHtml } from '@hmn/rtl-web-core/hooks'

import { Graphic, graphicVariants, Triangle } from '../../../Decoration/components'
import { HeadingLabel } from '../../../Heading/components/Label'
import { ColumnistCard, columnistCardVariants } from '..'
import styles from './Head.style'

const HeadStyled = styled.section(props => ({ ...styles(props) }))

function Head({
    className,
    label,
    labelLink,
    labelTitle,
    description,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    title,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    titleTagComponent: TitleTag,
    lead,
    columnist,
    columnistId,
    ...rest
}) {
    const labelHtmlProps = useInnerHtml(label)
    const descriptionHtmlProps = useInnerHtml(description)
    const leadHtmlProps = useInnerHtml(lead)
    return (
        <HeadStyled className={className} {...rest}>
            <div className="columnistHead">
                {label && (
                    <HeadingLabel
                        href={labelLink}
                        hrefAs={labelLink}
                        title={labelTitle}
                        lineAfter={false}
                        className="columnistHead_label">
                        <span {...labelHtmlProps} />
                    </HeadingLabel>
                )}
                {columnistId && (
                    <ColumnistCard
                        className="columnistHead_card"
                        columnist={columnist}
                        variant={columnistCardVariants.BETA}
                    />
                )}
                {lead && <div className="columnistHead_description" {...leadHtmlProps} />}
                {description && <span {...descriptionHtmlProps} className="columnistHead_lead" />}
            </div>
            <Media greaterThan="sm" scaleDown>
                <Triangle className="imageDecoration_triangle" />
                <Graphic className="imageDecoration_graphic" variant={graphicVariants.ALPHA} />
            </Media>
        </HeadStyled>
    )
}

Head.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    labelLink: PropTypes.string,
    labelTitle: PropTypes.string,
    description: PropTypes.string,
    title: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    lead: PropTypes.string,
    columnistId: PropTypes.string,
    columnist: PropTypes.oneOfType([() => null, PropTypes.object])
}

Head.defaultProps = {
    className: undefined,
    label: undefined,
    labelLink: undefined,
    labelTitle: undefined,
    description: undefined,
    title: undefined,
    titleTagComponent: 'h1',
    lead: undefined,
    columnistId: undefined,
    columnist: undefined
}

export default withErrorBoundary(Head, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[ColumnistHead]: ', error, componentStack)
    }
})
