import { toRem, toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    position: 'relative',
    padding: toRems([20, 0, 0, 0]),
    width: '100%',

    [theme.breakpoints.up('md')]: {
        background: theme.colors.background.body.light,
        padding: toRems([50, 0]),
        margin: 'auto',
        marginBottom: toRem(120)
    },
    '& .columnistHead': {
        // @TODO: connect to layout dimensions for head
        maxWidth: toRem(1215),
        padding: toRems([0, 25]),
        position: 'relative',
        zIndex: theme.zIndex.over,
        margin: '0 auto',
        textAlign: 'left',
        [theme.breakpoints.up('md')]: {
            textAlign: 'left'
        },
        '& .columnistCard': {
            marginLeft: toRem(12)
        },
        '&_card': {
            margin: toRems([40, 0, 45, 0]),
            [theme.breakpoints.up('md')]: {
                margin: toRems([0, 0, 75, 0])
            }
        },
        '&_label': {
            display: 'flex',
            justifyContent: 'left',
            marginBottom: toRem(80),
            [theme.breakpoints.down('sm')]: {
                marginBottom: toRem(12),
                justifyContent: 'flex-start',
                '& span::after': {
                    display: 'none'
                }
            }
        },
        '&_subtitle': {
            color: theme.colors.subtitle.default,
            fontSize: toRem(16),
            lineHeight: 1.4,
            [theme.breakpoints.up('md')]: {
                fontSize: toRem(20)
            }
        },
        '&_titleWrap': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'left'
        },
        '&_title': {
            margin: toRems([10, 'auto', 16]),
            color: theme.colors.text.default,
            [theme.breakpoints.up('md')]: {
                margin: toRems([15, 'auto'])
            }
        },
        '&_description': {
            marginBottom: toRem(25),
            fontSize: toRem(20),
            fontWeight: theme.typography.fontWeight.extraLight,
            lineHeight: 1.6,
            p: {
                fontSize: 'inherit',
                lineHeight: 'inherit',
                fontWeight: 'inherit',
                color: theme.colors.text.default
            },
            [theme.breakpoints.up('md')]: {
                marginBottom: toRem(25),
                fontSize: toRem(32)
            }
        },
        '&_lead': {
            marginBottom: toRem(50),
            fontSize: toRem(16),
            fontWeight: theme.typography.fontWeight.regular,
            lineHeight: 1.8,
            p: {
                fontSize: 'inherit',
                lineHeight: 'inherit',
                fontWeight: 'inherit',
                color: theme.colors.text.default
            },
            [theme.breakpoints.up('md')]: {
                marginBottom: toRem(115),
                fontSize: toRem(22)
            }
        }
    },
    '& .imageDecoration': {
        '&_triangle': {
            position: 'absolute',
            right: 0,
            bottom: 0
        },
        '&_graphic': {
            position: 'absolute',
            right: toRem(15),
            bottom: toRem(-20),
            [theme.breakpoints.up('md')]: {
                right: toRem(-25),
                bottom: toRem(-25)
            }
        }
    }
})

export default style
