import { Fragment } from 'react'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '../ErrorBoundary/ErrorBoundary.component'

function Media({ lessThan, scaleDown, className, children, isFragment }) {
    if (!children) {
        return null
    }

    const getId = () => {
        const device = lessThan ? 'mobile' : 'desktop'
        return scaleDown ? `${device}ScaleDown` : device
    }

    // @todo: revisit this cus tf?
    if (isFragment) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <Fragment id={getId()}>{children}</Fragment>
    }

    return (
        <div id={getId()} className={className}>
            {children}
        </div>
    )
}

Media.propTypes = {
    lessThan: PropTypes.string,
    scaleDown: PropTypes.bool,
    className: PropTypes.string,
    isFragment: PropTypes.bool
}

Media.defaultProps = {
    lessThan: undefined,
    scaleDown: false,
    className: undefined,
    isFragment: false
}

export default withErrorBoundary(Media, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Media]: ', error, componentStack)
    }
})
