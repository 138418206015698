import { toRem, toRems } from '../../../../helpers/theme'

const style = ({ theme, variant }) => {
    const circleSizes = {
        alpha: {
            md: [125, 135, 145, 165],
            sm: [100, 110, 120, 135]
        },
        beta: {
            md: [130, 140, 150, 165],
            sm: [85, 95, 105, 115]
        },
        gamma: {
            md: [95, 105, 115, 125],
            sm: [68, 74, 80, 86]
        }
    }

    const config = {
        variants: {
            alpha: {
                avatar: {
                    size: {
                        md: toRem(120),
                        sm: toRem(95)
                    },
                    placeholder: {
                        md: toRem(70),
                        sm: toRem(60)
                    }
                }
            },
            beta: {
                avatar: {
                    size: {
                        md: toRem(125),
                        sm: toRem(80)
                    },
                    placeholder: {
                        md: toRem(80),
                        sm: toRem(50)
                    }
                }
            },
            gamma: {
                avatar: {
                    size: {
                        md: toRem(90),
                        sm: toRem(70)
                    },
                    placeholder: {
                        md: toRem(60),
                        sm: toRem(40)
                    }
                }
            }
        },

        circle: {
            margin: {
                md: toRems(
                    circleSizes?.[variant]?.md.map(val => -val / 2),
                    true
                ),
                sm: toRems(
                    circleSizes?.[variant]?.sm.map(val => -val / 2),
                    true
                )
            },
            sizes: {
                md: toRems(circleSizes?.[variant]?.md, true),
                sm: toRems(circleSizes?.[variant]?.sm, true)
            },
            rotation: ['-40deg', '75deg', '30deg']
        }
    }

    const circles = [0, 1, 2, 3].reduce((acc, curr) => {
        acc[`& .avatarCircle_${curr}`] = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: `rotate(${config?.circle?.rotation?.[curr]})`,
            marginTop: config?.circle?.margin?.sm?.[curr],
            marginLeft: config?.circle?.margin?.sm?.[curr],
            width: config?.circle?.sizes?.sm?.[curr],
            height: config?.circle?.sizes?.sm?.[curr],
            [theme.breakpoints.up('md')]: {
                width: config?.circle?.sizes?.md?.[curr],
                height: config?.circle?.sizes?.md?.[curr],
                marginTop: config?.circle?.margin?.md?.[curr],
                marginLeft: config?.circle?.margin?.md?.[curr]
            }
        }
        return acc
    }, {})
    return {
        position: 'relative',
        margin: toRem(5),
        '& .avatarInner': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start'
        },
        '& .avatarLink': {
            display: 'flex',
            flexDirection: 'column'
        },
        '& .backgroundCircle': {
            background: theme.colors.columnist?.avatar?.background || theme.color.devWarning,
            borderRadius: '50%'
        },
        '& .avatarImage': {
            borderRadius: '50%',
            backgroundImage: 'none',
            '& img': {
                borderRadius: '50%',
                padding: toRem(5)
            },
            '&, &_wrapper': {
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: config?.variants?.[variant]?.avatar?.size?.sm,
                height: config?.variants?.[variant]?.avatar?.size?.sm,
                [theme.breakpoints.up('md')]: {
                    width: config?.variants?.[variant]?.avatar?.size?.md,
                    height: config?.variants?.[variant]?.avatar?.size?.md
                }
            },
            '&_placeholder': {
                position: 'relative',
                width: config?.variants?.[variant]?.avatar?.placeholder?.sm,
                height: config?.variants?.[variant]?.avatar?.placeholder?.sm,
                color: theme.colors.columnist?.avatar?.color || theme.color.devWarning,
                path: {
                    fill: 'currentColor'
                },
                [theme.breakpoints.up('md')]: {
                    width: config?.variants?.[variant]?.avatar?.placeholder?.md,
                    height: config?.variants?.[variant]?.avatar?.placeholder?.md
                }
            },
            '&_progressive': {
                position: 'relative',
                padding: 0
            }
        },
        ...circles
    }
}

export default style
